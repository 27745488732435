import React, { useState } from "react";

import { GridIcon, RefreshIcon, StartOverIcon } from "@hopper-ui/icons";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import useAuthenticatedUser from "@hooks/useAuthenticatedUser";
import useDelayedEffect from "@hooks/useDelayedEffect";
import useIsFeatureEnabled from "@hooks/useIsFeatureEnabled";

import GhostButton from "@components/button/GhostButton";
import PrimaryButton from "@components/button/PrimaryButton";
import RecognitionPromptStack from "@components/recognition-prompt/RecognitionPromptStack";

import type { ActivePrompt } from "@api/RecognitionActivityApiClient";
import type RecognitionCategory from "@core/enums/RecognitionCategory";
import { trackIntercomEvent } from "@core/intercom/intercom";
import { trackButtonClicked } from "@core/tracking/track";
import TrackingPropertyName from "@core/tracking/TrackingPropertyName";
import sleep from "@core/utils/sleep";
import "./recognition-select-prompt-step.scss";

interface Props {
    className?: string;
    correlationId: string;
    shuffledActivePrompts: ActivePrompt[];
    selectedPromptIndex?: number;
    onSubmit: (categoryId: RecognitionCategory, promptId: string, promptIndex: number) => void;
    isIntroAnimated: boolean;
    onIntroAnimationCompleted: () => void;
    onAnimationCompleted: () => void;
}

const RecognitionSelectPromptStep = ({ className, correlationId, shuffledActivePrompts, selectedPromptIndex, onSubmit, isIntroAnimated, onIntroAnimationCompleted, onAnimationCompleted }: Props) => {
    const { t } = useTranslation("activities");
    const isWorkleapBrandEnabled = useIsFeatureEnabled(feature => feature.useWorkleapBrand);
    const isMultipleRecipientsGoodVibesEnabled = useIsFeatureEnabled(feature => feature.multipleRecipientsGoodVibes);
    const isPublicGoodVibesEnabled = useIsFeatureEnabled(feature => feature.publicGoodVibes);

    const { isNetworkAdmin, isCompanyManager, isTeamManager } = useAuthenticatedUser();

    const [confirmed, setConfirmed] = useState(false);
    const [currentPromptIndex, setCurrentPromptIndex] = useState(selectedPromptIndex || 0);

    useDelayedEffect(() => {
        if (isIntroAnimated) {
            onIntroAnimationCompleted();
        }

        trackIntercomEvent("recognition-prompted-in-survey");

        if (isMultipleRecipientsGoodVibesEnabled) {
            trackIntercomEvent("Recognition Multiple Recipients");
        }

        if (isPublicGoodVibesEnabled) {
            trackIntercomEvent("recognition public recognition");
        }
    }, [], 1200);

    const handleOnSubmit = async() => {
        if (confirmed) {
            return;
        }

        const currentPromptIndexWithModulo = currentPromptIndex % shuffledActivePrompts.length;

        setConfirmed(true);
        trackButtonClicked(isNetworkAdmin, isCompanyManager, isTeamManager, "Recognition select card", "Pick card", {
            [TrackingPropertyName.CorrelationId]: correlationId,
            [TrackingPropertyName.CategoryId]: shuffledActivePrompts[currentPromptIndexWithModulo].categoryId,
            [TrackingPropertyName.PromptId]: shuffledActivePrompts[currentPromptIndexWithModulo].id
        });

        onSubmit(shuffledActivePrompts[currentPromptIndexWithModulo].categoryId, shuffledActivePrompts[currentPromptIndexWithModulo].id, currentPromptIndex);

        await sleep(600);
        onAnimationCompleted();
    };

    const handleOnShuffle = () => {
        trackButtonClicked(isNetworkAdmin, isCompanyManager, isTeamManager, "Recognition select card", "Shuffle cards", { [TrackingPropertyName.CorrelationId]: correlationId });

        setCurrentPromptIndex(currentState => currentState + 1);
    };

    const handleViewAllCards = () => {
        trackButtonClicked(isNetworkAdmin, isCompanyManager, isTeamManager, "Recognition select card", "View all cards", { [TrackingPropertyName.CorrelationId]: correlationId });

        if (isTeamManager || isCompanyManager) {
            window.location.href = `${window.env.APP_DASHBOARD_URL}/recognition/gallery/send`;
        } else {
            window.location.href = `${window.env.APP_DASHBOARD_URL}/my/recognition/send`;
        }
    };

    const handleOnPrevious = () => {
        trackButtonClicked(isNetworkAdmin, isCompanyManager, isTeamManager, "Recognition select card", "Previous card", { [TrackingPropertyName.CorrelationId]: correlationId });

        setCurrentPromptIndex(currentState => Math.max(0, currentState - 1));
    };

    const classes = classNames(
        "recognition-select-prompt-step",
        className, {
            "recognition-select-prompt-step--animated-intro": isIntroAnimated,
            "recognition-select-prompt-step--confirmed": confirmed
        }
    );

    const renderPreviousButton = () => {
        return (
            <div className="recognition-select-prompt-step__secondary-actions">
                <GhostButton
                    theme={isWorkleapBrandEnabled ? GhostButton.Theme.Dark : GhostButton.Theme.Light}
                    onClick={handleOnPrevious}
                    disabled={currentPromptIndex === 0}
                    iconLeading={<StartOverIcon size="md" />}
                >
                    {t("recognitionFrame.select-prompt-previous")}
                </GhostButton>
                <GhostButton
                    theme={isWorkleapBrandEnabled ? GhostButton.Theme.Dark : GhostButton.Theme.Light}
                    onClick={handleViewAllCards}
                    iconLeading={<GridIcon size="md" />}
                >
                    {t("recognitionFrame.select-prompt-all-cards")}
                </GhostButton>
            </div>
        );
    };

    return (
        <div className={classes}>
            <div className="recognition-select-prompt-step__title">
                {isMultipleRecipientsGoodVibesEnabled
                    ? t("recognitionFrame.select-prompt-title-multiple-recipients")
                    : t("recognitionFrame.select-prompt-title")
                }
            </div>
            <div className="recognition-select-prompt-step__subtitle">
                {isMultipleRecipientsGoodVibesEnabled
                    ? t("recognitionFrame.select-prompt-subtitle-multiple-recipients")
                    : t("recognitionFrame.select-prompt-subtitle")
                }
            </div>
            <RecognitionPromptStack
                className="recognition-select-prompt-step__prompt-stack"
                currentIndex={currentPromptIndex}
                buttonLabel={t("recognitionFrame.select-prompt-pick-card")}
                onSubmit={handleOnSubmit}
                prompts={shuffledActivePrompts}
                disabled={confirmed}
            />
            <PrimaryButton
                className="recognition-select-prompt-step__shuffle-card-button"
                iconLeading={<RefreshIcon size="lg" />}
                onClick={handleOnShuffle}
                data-public
            >
                {t("recognitionFrame.select-prompt-shuffle")}
            </PrimaryButton>
            {renderPreviousButton()}
        </div>
    );
};

export default RecognitionSelectPromptStep;